<template>
    <div>
        <div class="entity-details-wrapper">
            <!-- description as title-->
            <div class="p-grid details-title-main">
                <div class="p-col-12">
                    <div class="title-wrapper">
                        <span class="title">
                            {{ t('CLIENT_CONFIGURATION_USERS_EDIT_POPUP_TITLE', language) }}</span
                        >
                    </div>
                    <div class="p-mt-2">
                        <Tag
                            v-if="!userDetails.lastLogin"
                            class="user-pending"
                            value="Pending"
                            rounded
                        ></Tag>
                    </div>
                </div>
            </div>
            <!-- top header section -->
            <div class="p-mt-3">
                <div class="invite-user-details-section">
                    <!-- info -->
                    <div class="p-grid">
                        <div class="p-col-12">
                            <Message severity="info" :closable="false">
                                {{ t('UPDATE_USER_INFO_TEXT', language.value) }}</Message
                            >
                        </div>
                    </div>
                    <UserForm
                        ref="userFormCmp"
                        type="update-user"
                        :editable="editable"
                        :userDetails="userDetails"
                    />
                    <!-- update user button section -->
                    <div class="button-section">
                        <div class="p-grid action-btn-group">
                            <div class="p-col left-side-buttons">
                                <!-- edit button -->
                                <span
                                    v-if="
                                        role === UserRole.ADMIN ||
                                        role === UserRole.PERIUM_ADMIN ||
                                        role === UserRole.DEVELOPER_ADMIN
                                    "
                                >
                                    <Button
                                        @click="setEditable"
                                        :label="t('EDIT', language)"
                                        v-if="!editable"
                                    />
                                </span>
                            </div>
                            <div class="p-col right-side-buttons">
                                <!-- previous button -->
                                <span class="previous-btn" v-if="!editable && userDetails">
                                    <Button
                                        :disabled="userDetails.no === '001'"
                                        :label="t('PREVIOUS', language)"
                                        @click="goToRecord('previous')"
                                        class="p-button-outlined"
                                    />
                                </span>
                                <!-- next button -->
                                <span class="next-btn p-ml-3" v-if="!editable && userDetails">
                                    <Button
                                        :label="t('NEXT', language)"
                                        @click="goToRecord('next')"
                                        class="p-button-outlined"
                                        :disabled="userDetails.no === userDetails.lastRecordNo"
                                    />
                                </span>
                                <!-- cancel button-->
                                <span>
                                    <Button
                                        @click="setEditable"
                                        :label="t('CANCEL', language)"
                                        class="p-button-outlined"
                                        v-if="editable"
                                    />
                                </span>

                                <!-- save button -->
                                <span class="p-ml-3">
                                    <Button
                                        @click="saveUser"
                                        :label="t('SAVE', language)"
                                        v-if="editable"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'
import utils from '@/shared/mixins/utils'
import UserForm from '@/shared/components/UserForm.vue'
import { UserRole } from '@/shared/enum/general-enum'

export default defineComponent({
    props: {
        userDetails: {
            type: Object,
        },
    },
    components: {
        UserForm,
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const userFormCmp = ref(null)
        const editable = ref(false)
        const { role } = useState(['role'])

        // update user
        const saveUser = async () => {
            userFormCmp.value.validateForm()
            if (utils.hasErrors(userFormCmp.value.errors)) {
                return
            }

            emit('save-user', userFormCmp.value.USERFORM)
        }

        const setEditable = () => {
            editable.value = !editable.value
        }

        // go to record (previous/ next)
        const goToRecord = (type) => {
            emit('go-to-record', { no: props.userDetails.no, type })
        }
        return {
            t,
            language,
            saveUser,
            userFormCmp,
            editable,
            setEditable,
            role,
            UserRole,
            goToRecord,
        }
    },
})
</script>

<style lang="scss" scoped></style>















































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import {
    computed,
    ComputedRef,
    defineComponent,
    onMounted,
    Ref,
    ref,
    watch,
} from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import queryProfile from '@/shared/queries/clientConfigQuery'
import utils from '@/shared/mixins/utils'
import SliderExtend from '@/shared/components/SliderExtend.vue'
import { TranslateResult } from 'vue-i18n'
import clientConfigData from '@/assets/data/client-configuration-data.json'
import ToggleSwitch from '@/shared/components/ToggleSwitch.vue'
import { YesNo } from '@/shared/enum/general-enum'
import InfoIcon from '@/shared/components/InfoIcon.vue'

export default defineComponent({
    name: 'PeriumProfile',
    components: { SliderExtend, ToggleSwitch, InfoIcon},
    props: {
        editable: {
            type: Boolean,
        },
        clientConfigData: {
            type: Object,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const norms = ref([])
        const selectedNorms = ref([])
        const selectedMSRNorms = ref([])
        const copyOfNorms = ref([])
        const copyOfMSRNorms = ref([])
        const listOfNorms = ref([])
        const listOfMSRNorms = ref([])
        const priorityCategories = ref([])
        const labelInfomationOpRiskTolerance = ref(null)
        const isPCAvailable = ref(false)
        const msrNorms = ref([])
        // risk tolerance default values
        const RISK_TOLERANCE_DEFAULT_VALUES = {
            GREEN: 8,
            ORANGE: 12,
            RED: 25,
        }
        const RISK_TOLERANCE_GREEN = ref({
            min: 1,
            max: 25,
        })
        const RISK_TOLERANCE_ORANGE = ref({
            min: RISK_TOLERANCE_GREEN.value.min + 1,
            max: 25,
        })
        const RISK_TOLERANCE_RED = ref({
            min: RISK_TOLERANCE_ORANGE.value.min + 1,
            max: 25,
        })
        const TESTRANGE = ref({
            min: 1,
            range: [8, 12],
            max: 25,
        })
        const periumProfileForm = ref({
            MSR_NORMS: [],
            NORMS: [],
            SELECTEDNORMS: [],
            SELECTED_RISK_SETS: [],
            SELECTED_MSR_NORMS: [],
            PRIORITY_CATEGORIES: [],
            RISK_TOLERANCE: {
                GREEN: RISK_TOLERANCE_DEFAULT_VALUES.GREEN,
                ORANGE: RISK_TOLERANCE_DEFAULT_VALUES.ORANGE,
                RED: RISK_TOLERANCE_DEFAULT_VALUES.RED,
            },
            DATA_SHARE: 1, // by default set to `yes === 1`
            TWO_FACTOR_REQUIRED: 1,
            PRIORITY_FILTER: isPCAvailable.value,
            EMAIL_SCHEDULED: false,
        })
        const dataShareDropdown = ref([])
        const twoFactorRequiredDropdown = ref([])
        const dataShare: Ref<TranslateResult> = ref('')
        const twoFactorRequired: Ref<TranslateResult> = ref('')

        // generate label for norm or MSR norm
        const normDisplayLabel = (norm) => {
            if (norm?.displayName) {
                // if displayName exists and is not an empty string, use that
                return norm.displayName
            }
            else if (norm?.name?.length){
                return `${norm.name} - ${norm.version}`
            } else {
                return `[nameless norm]`
            }
        }

        const compareNormsByLabel = (normA, normB) => {
            const collator = Intl.Collator(undefined, {
                numeric: true,      // Natural sorting for numeric strings
                sensitivity: 'base' // Case-insensitive sorting
            })
            return collator.compare(
                normDisplayLabel(normA), 
                normDisplayLabel(normB)
            )
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            labelInfomationOpRiskTolerance.value.toggle(event)
        }

        // set norms on view
        const setNorms = () => {
            selectedNorms.value = []
            // find the list of selected norms by their ids
            // and store them in `listOfMSRNorms` to display
            listOfNorms.value = props.clientConfigData.selectedNorms.map(
                (norm) => copyOfNorms.value.find(
                    (val) => val.id === norm.id
                )
            )
            listOfNorms.value.sort(compareNormsByLabel)
        }

        // set msr norms on view
        const setMSRNorms = () => {
            selectedMSRNorms.value = []
            // find the list of selected msr norms by their ids
            // and store them in `listOfMSRNorms` to display
            listOfMSRNorms.value = props.clientConfigData.selectedMsrNorms.map(
                (norm) => copyOfMSRNorms.value.find(
                    (val) => val.id === norm.id
                )
            )
            listOfMSRNorms.value.sort(compareNormsByLabel)
        }

        //  get norms data
        const getNorms = async () => {
            norms.value = []
            const getNormsQuery = `
                query{
                    norms{
                        items{
                            id
                            name
                            displayName
                            hasPriorityCategory
                            version
                            description
                        }
                        
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getNormsQuery)
            } catch (err) {
                throw Error('Error while retrieving norms')
            }
            if (result) {
                norms.value = [...result.data.norms.items].sort(compareNormsByLabel)
                copyOfNorms.value = [...result.data.norms.items].sort(compareNormsByLabel)
            }
        }

        //  get norms data
        const getMSRNorms = async () => {
            msrNorms.value = []
            const getMSRNormsQuery = `
                query{
                    msrNorms{
                        items{
                            id
                            name
                            displayName
                            version
                            description
                        }
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getMSRNormsQuery)
            } catch (err) {
                throw Error('Error while retrieving msr norms')
            }
            if (result) {
                msrNorms.value = [...result.data.msrNorms.items].sort(compareNormsByLabel)
                copyOfMSRNorms.value = [...result.data.msrNorms.items].sort(compareNormsByLabel)
            }
        }

        //  get priority categories data
        const getPriorityCategories = async () => {
            priorityCategories.value = []
            const getNormsQuery = `
                query{
                    priorityCategories{
                        ${queryProfile.PRIORITY_CATEGORY}                        
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getNormsQuery)
            } catch (err) {
                throw Error('Error while retrieving priority categories')
            }

            if (result) {
                priorityCategories.value = result.data.priorityCategories
            }
        }

        // display priority categories filter if user has selected ISO 27001, NEN7510, IPB and BIC norms
        const IsPriorityCatNorms: ComputedRef<boolean> = computed((): boolean => {
            // get hasPriorityCategory values of selectedNorms
            const checkHasPriorityFilter = selectedNorms.value.map(
                (norm) => norm.hasPriorityCategory
            )
            // return if checkHasPriorityFilter has any true values (norms which has priority categoary)
            return checkHasPriorityFilter.includes(true)
        })

        // check for the availability of priority categories
        const isPriorityCategoryAvailable = () => {
            /* check whether priority category field is display and at least one category is selected.
            update isPCAvailable accordingly  */

            isPCAvailable.value = !(
                !IsPriorityCatNorms.value ||
                periumProfileForm.value.PRIORITY_CATEGORIES.length === 0
            )
            // if isPCAvailable is false, PRIORITY_FILTER should also be false
            periumProfileForm.value.PRIORITY_FILTER = isPCAvailable.value
        }

        // Move norms from norms to seleted norms
        const select = () => {
            for (const selectedItem of periumProfileForm.value.NORMS) {
                // removing selected items from left section (`norms.value`)
                const idx = norms.value.indexOf(selectedItem)
                const removed = norms.value.splice(idx, 1)[0]
                // adding removed element to right section (`selectedNorms.value`)
                if (removed) selectedNorms.value.push(removed)
                // sort
                selectedNorms.value.sort(compareNormsByLabel)
            }
            // Unselect rightItems(selected norms of form model)
            periumProfileForm.value.NORMS = []
            periumProfileForm.value.SELECTEDNORMS = []
            isPriorityCategoryAvailable()
        }

        // Move norms from msr norms to seleted msr norms
        const selectMSRNorms = () => {
            for (const selectedItem of periumProfileForm.value.MSR_NORMS) {
                // removing selected items from left section (`msrNorms.value`)
                const idx = msrNorms.value.indexOf(selectedItem)
                const removed = msrNorms.value.splice(idx, 1)[0]
                // adding removed element to right section (`selectedMSRNorms.value`)
                if (removed) selectedMSRNorms.value.push(removed)
                // sort
                selectedMSRNorms.value.sort(compareNormsByLabel)
            }
            // Unselect rightItems(selected norms of form model)
            periumProfileForm.value.MSR_NORMS = []
            periumProfileForm.value.SELECTED_MSR_NORMS = []
        }

        // deselect right items(selected norms)
        const deselect = () => {
            for (const selectedItem of periumProfileForm.value.SELECTEDNORMS) {
                // removing selected items from right section (`selectedNorms.value`)
                const idx = selectedNorms.value.indexOf(selectedItem)
                const removed = selectedNorms.value.splice(idx, 1)[0]
                // adding removed element to left section (`norms.value`)
                removed && norms.value.push(removed)
                // sort
                norms.value.sort(compareNormsByLabel)
            }
            // Unselect rightItems(selected norms of form model)
            periumProfileForm.value.SELECTEDNORMS = []
            isPriorityCategoryAvailable()
        }

        // deselect right items(selected msr norms)
        const deselectMSRNorms = () => {
            for (const selectedItem of periumProfileForm.value.SELECTED_MSR_NORMS) {
                // removing selected items from right section (`selectedMSRNorms.value`)
                const idx = selectedMSRNorms.value.indexOf(selectedItem)
                const removed = selectedMSRNorms.value.splice(idx, 1)[0]
                // adding removed element to left section (`msrNorms.value`)
                removed && msrNorms.value.push(removed)
                // sort
                msrNorms.value.sort(compareNormsByLabel)
            }
            // Unselect rightItems(selected norms of form model)
            periumProfileForm.value.SELECTED_MSR_NORMS = []
        }

        // set form data on edit
        const setFormData = () => {
            selectedNorms.value = []
            selectedMSRNorms.value = []
            //  resetting `msrNorms` and `norms` values as they are updating everytime `setFormData` function is called by cancel
            msrNorms.value = [...copyOfMSRNorms.value]
            norms.value = [...copyOfNorms.value]
            const clientConfigData = props.clientConfigData

            clientConfigData.selectedNorms.map((norm) => {
                const normObj = copyOfNorms.value.filter((val) => val.id === norm.id)[0]
                periumProfileForm.value.NORMS.push(normObj)
            })
            clientConfigData.selectedMsrNorms.map((norm) => {
                const normObj = copyOfMSRNorms.value.filter((val) => val.id === norm.id)[0]
                periumProfileForm.value.MSR_NORMS.push(normObj)
            })

            select()
            selectMSRNorms()
            // set risk tolerance, if they exist
            periumProfileForm.value.RISK_TOLERANCE = {
                GREEN: clientConfigData.riskToleranceGreen
                    ? clientConfigData.riskToleranceGreen
                    : RISK_TOLERANCE_DEFAULT_VALUES.GREEN,
                ORANGE: clientConfigData.riskToleranceOrange
                    ? clientConfigData.riskToleranceOrange
                    : RISK_TOLERANCE_DEFAULT_VALUES.ORANGE,
                RED: clientConfigData.riskToleranceRed
                    ? clientConfigData.riskToleranceRed
                    : RISK_TOLERANCE_DEFAULT_VALUES.RED,
            }
            if (clientConfigData.selectedPriorityCategories.length > 0)
                periumProfileForm.value.PRIORITY_CATEGORIES =
                    clientConfigData.selectedPriorityCategories.map((category) => category.id)

            // set maximum values for green and orange based on previously saved values
            RISK_TOLERANCE_GREEN.value.max = periumProfileForm.value.RISK_TOLERANCE.ORANGE - 1
            RISK_TOLERANCE_ORANGE.value.max = periumProfileForm.value.RISK_TOLERANCE.RED - 1

            //TODO: make Data share and 2fa to boolean values instead of numbers (Backend to update)
            // 1 = Yes, 2= No
            periumProfileForm.value.DATA_SHARE = clientConfigData.dataShare ? 1 : 2
            periumProfileForm.value.TWO_FACTOR_REQUIRED = clientConfigData.twoFactorRequired ? 1 : 2
            periumProfileForm.value.EMAIL_SCHEDULED = clientConfigData.isEmailReportScheduled
            periumProfileForm.value.SELECTED_RISK_SETS = clientConfigData.selectedRiskSets.map(riskSet => riskSet.id)
            isPriorityCategoryAvailable()
            periumProfileForm.value.PRIORITY_FILTER = clientConfigData.priorityCategoryActivated
        }

        // clear form on cancel
        const cancelForm = () => {
            periumProfileForm.value = {
                MSR_NORMS: [],
                NORMS: [],
                SELECTED_RISK_SETS: [],
                SELECTEDNORMS: [],
                SELECTED_MSR_NORMS: [],
                PRIORITY_CATEGORIES: [],
                RISK_TOLERANCE: {
                    GREEN: RISK_TOLERANCE_DEFAULT_VALUES.GREEN,
                    ORANGE: RISK_TOLERANCE_DEFAULT_VALUES.ORANGE,
                    RED: RISK_TOLERANCE_DEFAULT_VALUES.RED,
                },
                DATA_SHARE: 1, // by default set to `yes === 1`
                TWO_FACTOR_REQUIRED: 1,
                PRIORITY_FILTER: isPCAvailable.value,
                EMAIL_SCHEDULED: false,
            }
            selectedNorms.value = []
            selectedMSRNorms.value = []
        }

        // set risk tolerance mix and max values based on each slider change
        const setRiskToleranceValues = (color: string) => {
            if (color === 'green') {
                /* if existing Orange value is not initial minimum value of Orange, 
                update Orange value based on Green value */
                if (periumProfileForm.value.RISK_TOLERANCE.ORANGE === 2) {
                    periumProfileForm.value.RISK_TOLERANCE.ORANGE =
                        periumProfileForm.value.RISK_TOLERANCE.GREEN + 1
                }
                // minimum Orange value will be set to next Green value
                RISK_TOLERANCE_ORANGE.value.min = periumProfileForm.value.RISK_TOLERANCE.GREEN + 1
                /* if existing Red value is not initial minimum value of Red, 
                update Red value based on Orange value */
                if (periumProfileForm.value.RISK_TOLERANCE.RED === 3) {
                    periumProfileForm.value.RISK_TOLERANCE.RED =
                        periumProfileForm.value.RISK_TOLERANCE.ORANGE + 1
                }
                // Minimum Red value will be next Orange value
                RISK_TOLERANCE_RED.value.min = periumProfileForm.value.RISK_TOLERANCE.ORANGE + 1
            } else if (color === 'orange') {
                /* if existing Red value is not initial minimum value of Red, 
                update Red value based on Orange value */
                if (periumProfileForm.value.RISK_TOLERANCE.RED === 3) {
                    periumProfileForm.value.RISK_TOLERANCE.RED =
                        periumProfileForm.value.RISK_TOLERANCE.ORANGE + 1
                }
                // Minimum Red value will be next Orange value
                RISK_TOLERANCE_RED.value.min = periumProfileForm.value.RISK_TOLERANCE.ORANGE + 1
                // maximum Green value will be set to previous minimum Orange value
                RISK_TOLERANCE_GREEN.value.max = periumProfileForm.value.RISK_TOLERANCE.ORANGE - 1
            } else {
                // maximum Orange value will be set to previous minimum Red value
                RISK_TOLERANCE_ORANGE.value.max = periumProfileForm.value.RISK_TOLERANCE.RED - 1
            }
        }

        // convert id values to name values
        const convertIdToNames = () => {
            // if datashare is true, mapping id of Yes (1) or No(2) from Yes/No dropdown
            const dataShareId = props.clientConfigData.dataShare ? 1 : 2
            const dataShareValue = utils.idToName(clientConfigData.DATA_SHARE, dataShareId)
            dataShare.value = t(dataShareValue, language.value)
            //TODO: make Data share and 2fa to boolean values instead of numbers (Backend to update)
            // 1 = Yes, 2= No
            const twoFactorRequiredId = props.clientConfigData.twoFactorRequired ? 1 : 2
            const twoFactorRequiredValue = utils.idToName(
                // temporary using DATA_SHARE values here.
                clientConfigData.DATA_SHARE,
                twoFactorRequiredId
            )
            twoFactorRequired.value = t(twoFactorRequiredValue, language.value)
        }

        // set data share dropdown values
        const setDataShareDropdown = () => {
            dataShareDropdown.value = []

            clientConfigData.DATA_SHARE.map((data) => {
                dataShareDropdown.value.push({
                    id: data.ID,
                    value: t(data.VALUE, language.value),
                })
            })
        }

        // set data share dropdown values
        const setTwoFactorRequiredDropdown = () => {
            twoFactorRequiredDropdown.value = []

            clientConfigData.DATA_SHARE.map((data) => {
                twoFactorRequiredDropdown.value.push({
                    id: data.ID,
                    value: t(data.VALUE, language.value),
                })
            })
        }

        // priority category toggle
        const priorityFilterToggle = (toggleValue: boolean) => {
            periumProfileForm.value.PRIORITY_FILTER = toggleValue
        }

        // email scheduler toggle
        const emailSchedulerToggle = (toggleValue: boolean) => {
            periumProfileForm.value.EMAIL_SCHEDULED = toggleValue
        }

        onMounted(async () => {
            setDataShareDropdown()
            setTwoFactorRequiredDropdown()
            getPriorityCategories()
            await getMSRNorms()
            await getNorms()

            // load default values
            setRiskToleranceValues('green')
            if (props.clientConfigData) {
                setMSRNorms()
                setNorms()
                convertIdToNames()
                setFormData()
            }
        })

        //  watch for clientConfigData data change
        watch(
            () => Object.assign({}, props.clientConfigData),
            async () => {
                if (props.clientConfigData) {
                    setMSRNorms()
                    setNorms()
                    convertIdToNames()
                    setFormData()
                }
            }
        )
        // watch for language chaneges and update values
        watch(language, () => {
            setDataShareDropdown()
            setTwoFactorRequiredDropdown()
            if (props.clientConfigData) {
                convertIdToNames()
            }
        })

        return {
            t,
            language,
            periumProfileForm,
            norms,
            msrNorms,
            selectedNorms,
            selectedMSRNorms,
            select,
            deselect,
            cancelForm,
            listOfNorms,
            listOfMSRNorms,
            setFormData,
            priorityCategories,
            IsPriorityCatNorms,
            utils,
            toggleInformation,
            labelInfomationOpRiskTolerance,
            isPriorityCategoryAvailable,
            setRiskToleranceValues,
            RISK_TOLERANCE_GREEN,
            RISK_TOLERANCE_ORANGE,
            RISK_TOLERANCE_RED,
            TESTRANGE,
            dataShareDropdown,
            twoFactorRequiredDropdown,
            dataShare,
            twoFactorRequired,
            priorityFilterToggle,
            isPCAvailable,
            deselectMSRNorms,
            selectMSRNorms,
            normDisplayLabel,
            emailSchedulerToggle,
            YesNo,
        }
    },
})



















































































































import { useState } from '@/shared/mixins/helpers'
import { defineComponent, ref, watch} from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import clientConfigData from '@/assets/data/client-configuration-data.json'
import utils from '@/shared/mixins/utils'
import { translateChoices } from '@/shared/mixins/i18n'

export default defineComponent({
    name: 'ContactDetails',
    props: {
        editable: {
            type: Boolean,
        },
        clientConfigData: {
            type: Object,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const interfaceLanguageChoices = translateChoices(clientConfigData.INTERFACE_LANGUAGE)

        const CONTACT_DETAILS_FORM = ref({
            NAME: '',
            EMAIL: '',
            PHONE: '',
            INTERFACE_LANGUAGE: '',
        })
        const errors = ref({
            EMAIL: { invalidValue: false },
        })

        const validateForm = () => {
            errors.value = {
                EMAIL: { invalidValue: false },
            }
            if (CONTACT_DETAILS_FORM.value.EMAIL)
                // Validate submitted email
                errors.value.EMAIL.invalidValue = utils.validateFields(
                    CONTACT_DETAILS_FORM.value.EMAIL,
                    'email'
                )
        }

        // clear form data on cancel
        const cancelForm = () => {
            CONTACT_DETAILS_FORM.value = {
                NAME: '',
                EMAIL: '',
                PHONE: '',
                INTERFACE_LANGUAGE: '',
            }
        }

        // set form data on edit
        const setFormData = () => {
            const clientConfigData = props.clientConfigData
            CONTACT_DETAILS_FORM.value = {
                NAME: clientConfigData.contactName,
                EMAIL: clientConfigData.contactEmail,
                PHONE: clientConfigData.contactTelephone,
                INTERFACE_LANGUAGE: clientConfigData.interfaceLanguage,
            }
        }

        //  watch for clientConfigData data change
        watch(
            () => Object.assign({}, props.clientConfigData),
            () => {
                if (props.clientConfigData) {
                    setFormData()
                }
            }
        )

        return {
            t,
            language,
            CONTACT_DETAILS_FORM,
            interfaceLanguageChoices,
            cancelForm,
            setFormData,
            errors,
            validateForm,
            utils,
        }
    },
})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"details-section"},[_c('form',[_c('div',{staticStyle:{"margin":"0.5rem"}},[_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-2 label-section"},[_vm._v(" "+_vm._s(_vm.t('CLIENT_CONFIGURATION_CONTACT_DETAILS_NAME', _vm.language))+" ")]),_c('div',{staticClass:"p-col-10 data-section"},[(!_vm.editable && _vm.clientConfigData)?_c('div',[_vm._v(" "+_vm._s(_vm.clientConfigData.contactName)+" ")]):_vm._e(),(_vm.editable)?_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('InputText',{attrs:{"placeholder":_vm.t(
                                        'CLIENT_CONFIGURATION_CONTACT_DETAILS_NAME_PLACEHOLDER',
                                        _vm.language
                                    )},model:{value:(_vm.CONTACT_DETAILS_FORM.NAME),callback:function ($$v) {_vm.$set(_vm.CONTACT_DETAILS_FORM, "NAME", $$v)},expression:"CONTACT_DETAILS_FORM.NAME"}})],1)]):_vm._e()])]),_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-2 label-section"},[_vm._v(" "+_vm._s(_vm.t('CLIENT_CONFIGURATION_CONTACT_DETAILS_EMAIL', _vm.language))+" ")]),_c('div',{staticClass:"p-col-10 data-section"},[(!_vm.editable && _vm.clientConfigData)?_c('div',[_vm._v(" "+_vm._s(_vm.clientConfigData.contactEmail)+" ")]):_vm._e(),(_vm.editable)?_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('InputText',{class:[_vm.errors.EMAIL.invalidValue ? 'input-error' : ''],attrs:{"placeholder":_vm.t(
                                        'CLIENT_CONFIGURATION_CONTACT_DETAILS_EMAIL_PLACEHOLDER',
                                        _vm.language
                                    )},model:{value:(_vm.CONTACT_DETAILS_FORM.EMAIL),callback:function ($$v) {_vm.$set(_vm.CONTACT_DETAILS_FORM, "EMAIL", $$v)},expression:"CONTACT_DETAILS_FORM.EMAIL"}}),(_vm.errors.EMAIL.invalidValue)?_c('small',{staticClass:"p-error"},[_vm._v(_vm._s(_vm.t('ERROR__INVALID_EMAIL', _vm.language)))]):_vm._e()],1)]):_vm._e()])]),_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-2 label-section"},[_vm._v(" "+_vm._s(_vm.t('CLIENT_CONFIGURATION_CONTACT_DETAILS_TELEPHONE_NUMBER', _vm.language))+" ")]),_c('div',{staticClass:"p-col-10 data-section"},[(!_vm.editable && _vm.clientConfigData)?_c('div',[_vm._v(" "+_vm._s(_vm.clientConfigData.contactTelephone)+" ")]):_vm._e(),(_vm.editable)?_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('InputText',{attrs:{"placeholder":_vm.t(
                                        'CLIENT_CONFIGURATION_CONTACT_DETAILS_TELEPHONE_NUMBER_PLACEHOLDER',
                                        _vm.language
                                    ),"maxlength":"10"},on:{"keypress":_vm.utils.validateNumber},model:{value:(_vm.CONTACT_DETAILS_FORM.PHONE),callback:function ($$v) {_vm.$set(_vm.CONTACT_DETAILS_FORM, "PHONE", $$v)},expression:"CONTACT_DETAILS_FORM.PHONE"}})],1)]):_vm._e()])]),_c('div',{staticClass:"p-grid"},[_c('div',{staticClass:"p-col-2 label-section"},[_vm._v(" "+_vm._s(_vm.t('CLIENT_CONFIGURATION_CONTACT_DETAILS_LANGUAGE_PREFERENCE', _vm.language))+" ")]),_c('div',{staticClass:"p-col-10 data-section"},[(!_vm.editable && _vm.clientConfigData)?_c('div',[_vm._v(" "+_vm._s(_vm.t(_vm.interfaceLanguageChoices.getKey(_vm.clientConfigData.interfaceLanguage), _vm.language))+" ")]):_vm._e(),(_vm.editable)?_c('div',{staticClass:"p-fluid"},[_c('div',{staticClass:"p-field"},[_c('Dropdown',{attrs:{"options":_vm.interfaceLanguageChoices.options,"optionLabel":"label","optionValue":"value","placeholder":_vm.interfaceLanguageChoices.placeholder},model:{value:(_vm.CONTACT_DETAILS_FORM.INTERFACE_LANGUAGE),callback:function ($$v) {_vm.$set(_vm.CONTACT_DETAILS_FORM, "INTERFACE_LANGUAGE", $$v)},expression:"CONTACT_DETAILS_FORM.INTERFACE_LANGUAGE"}})],1)]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }




























































































































































































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, reactive, UnwrapRef, ref, Ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import clientConfigStoredData from '@/assets/data/client-configuration-data.json'
import utils from '@/shared/mixins/utils'
import dashboardQuery from '@/shared/queries/dashboardQueries'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import {ParentSectorDTO, SectorDTO} from '@/dto/backend-response/generalDTO'

interface ClientConfigFormData {
    COMPANY_NAME: string;
    COC_NUMBER: string;
    ADDRESSES_BUSSINESS: {
        COMPANY_STREET: string;
        COMPANY_STREET_NO: string;
        COMPANY_POSTALCODE: string;
        COMPANY_CITY: string;
        COMPANY_COUNTRY: string;
        COMPANY_PHONE: string;
    };
    ADDRESSES_BILLING: {
        FINANCE_STREET: string;
        FINANCE_STREET_NO: string;
        FINANCE_POSTALCODE: string;
        FINANCE_CITY: string;
        FINANCE_COUNTRY: string;
        FINANCE_EMAIL: string;
    };
    COMPANY_SIZE: string;
    COMPANY_SECTOR: string | null;
    COMPANY_SUB_SECTOR: string | null;
}

const initializeEmptyForm = (): ClientConfigFormData => ({
    COMPANY_NAME: '',
    COC_NUMBER: '',
    ADDRESSES_BUSSINESS: {
        COMPANY_STREET: '',
        COMPANY_STREET_NO: '',
        COMPANY_POSTALCODE: '',
        COMPANY_CITY: '',
        COMPANY_COUNTRY: '',
        COMPANY_PHONE: '',
    },
    ADDRESSES_BILLING: {
        FINANCE_STREET: '',
        FINANCE_STREET_NO: '',
        FINANCE_POSTALCODE: '',
        FINANCE_CITY: '',
        FINANCE_COUNTRY: '',
        FINANCE_EMAIL: '',
    },
    COMPANY_SIZE: '',
    COMPANY_SECTOR: null,
    COMPANY_SUB_SECTOR:  null,
})

const initializeFormFromExistingConfig = (
    clientConfigData: Record<string, any>
): ClientConfigFormData => ({
    COMPANY_NAME: clientConfigData?.companyName,
    COC_NUMBER: clientConfigData?.cocNumber,
    ADDRESSES_BUSSINESS: {
        COMPANY_STREET: clientConfigData?.companyStreet,
        COMPANY_STREET_NO: clientConfigData?.companyStreetNumber,
        COMPANY_POSTALCODE: clientConfigData?.companyPostalcode,
        COMPANY_CITY: clientConfigData?.companyCity,
        COMPANY_COUNTRY: clientConfigData?.companyCountry,
        COMPANY_PHONE: clientConfigData?.companyTelephone,
    },
    ADDRESSES_BILLING: {
        FINANCE_STREET: clientConfigData?.financeStreet,
        FINANCE_STREET_NO: clientConfigData?.financeStreetNumber,
        FINANCE_POSTALCODE: clientConfigData?.financePostalcode,
        FINANCE_CITY: clientConfigData?.financeCity,
        FINANCE_COUNTRY: clientConfigData?.financeCountry,
        FINANCE_EMAIL: clientConfigData?.financeEmail,
    },
    COMPANY_SIZE: clientConfigData.companySize,
    COMPANY_SECTOR: clientConfigData.companySector?.sector?.key,
    COMPANY_SUB_SECTOR: clientConfigData.companySector?.subSector?.key,
})


// get sectors => subsectors => norms
const getSectors = async (): Promise<ParentSectorDTO[]> => {
    const sectorQuery = `
        query {
            sectors{
                ${dashboardQuery.WIZARD_STEP3_SECTORS}
            }
        }
    `
    const result = await apolloClient.getGraphqlData(sectorQuery)
    return result.data.sectors
}

export default defineComponent({
    name: 'CompanyDetails',
    props: {
        editable: {
            type: Boolean,
        },
        clientConfigData: {
            type: Object,
        },
    },
    setup (props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const compSizeDropdown = ref(clientConfigStoredData.COMP_SIZE)
        const sectorData: Ref<ParentSectorDTO[]> = ref([])
        const companyDetailsForm: UnwrapRef<ClientConfigFormData> = reactive(initializeEmptyForm())


        const errors = ref({
            COMPANY_SUB_SECTOR: { missingValue: false },
            FINANCE_EMAIL: { invalidValue: false },
        })
        // get localized country list
        const countries = computed(() => {
            const lang = language.value === 'dutch' ? 'nl' : 'en'
            return utils.countries(lang)
        })

        const subSectorOptions: Ref<SectorDTO[]> = computed(
            () => sectorData.value.find(
                sector => sector.key == companyDetailsForm.COMPANY_SECTOR
            )?.subSectors ?? []
        )

        const displaySubSectors = computed(
            () => {
                if (!props.editable) {
                    // When viewing data, show subsector if it is set
                    return props.clientConfigData?.companySector?.subSector?.name
                }
                // When editing data, show subsector if there are subsection options
                return subSectorOptions.value.length
            }
        )

        //  validating form
        const validateForm = () => {
            if (displaySubSectors.value) {
                errors.value.COMPANY_SUB_SECTOR.missingValue = utils.validateFields(
                    companyDetailsForm.COMPANY_SUB_SECTOR,
                    'string'
                )
            } else {
                errors.value.COMPANY_SUB_SECTOR.missingValue = false
            }
            if (companyDetailsForm.ADDRESSES_BILLING.FINANCE_EMAIL)
                errors.value.FINANCE_EMAIL.invalidValue = utils.validateFields(
                    companyDetailsForm.ADDRESSES_BILLING.FINANCE_EMAIL,
                    'email'
                )
        }

        // clear form data on cancel
        const clearForm = () => {
            Object.assign(companyDetailsForm, initializeEmptyForm())
        }

        // 
        const resetFormToPropsData = () => {
            Object.assign(
                companyDetailsForm,
                initializeFormFromExistingConfig(
                    props.clientConfigData
                )
            )
        }

        //  watch for clientConfigData data change
        watch(
            () => props.clientConfigData,
            async () => {
                sectorData.value = await getSectors()
                if (props.clientConfigData) {
                    resetFormToPropsData()
                }
            },
            {
                immediate: true,
            }
        )

        watch(
            () => subSectorOptions.value,
            (options) => {
                if (!options.find(({key}) => key === companyDetailsForm.COMPANY_SUB_SECTOR)) {
                    console.log("Clearning sub-sector")
                    companyDetailsForm.COMPANY_SUB_SECTOR = null
                }
            }
        )

        return {
            t,
            language,
            companyDetailsForm,
            compSizeDropdown,
            sectorData,
            subSectorOptions,
            displaySubSectors,
            clearForm,
            resetFormToPropsData,
            clientConfigStoredData,
            utils,
            errors,
            validateForm,
            countries,
        }
    }
})


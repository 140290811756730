import { computed, reactive } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import { useState } from '@/shared/mixins/helpers'

/*
 * Translate the choices for a specific dropdown, and the placeholder text.
 * We can provide a prefix to select the keys from a message namespace if
 * nested messages are used.
 */
export const translateChoices = (options, keyPrefix = "", placeholderKey = "SELECT") => {
    const { t } = useI18n();
    const { language } = useState(['language'])

    // Build the complete translation key for one option
    const optionKey = (key: string) => keyPrefix + key

    // Get the key based on a value. Only needed in cases where the value
    // is not identical to the key.
    const getKey = (value) => optionKey(
        options.find(
            (option) => option.value === value
        ).key
    )

    // Computed property to add a label field to the options, which will
    // react to changes in the language and translation store.
    const optionsWithLabels = computed(() => options.map(({key, value}) =>
        ({
            label: t(optionKey(key), language.value),
            value,
        })
    ))

    // Translate placeholder as well. We could do this separately but it is 
    // nice to keep the placeholder and options together.
    const placeholder = computed(() => t(placeholderKey, language.value))

    /*
     * Return a single reactive object instead of returning the objects separetely
     * to keep the namespace of the caller clean, while allowing easy use in templates.
     * Without wrapping in reactive the caller would need to destructure the return value
     * or use .value in the template part of components. 
     */
    return reactive({
        options: optionsWithLabels,
        placeholder,
        getKey
    })
}
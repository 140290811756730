









































































































import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import ContactDetails from '@/components/client-configuration/ContactDetails.vue'
import CompanyDetails from '@/components/client-configuration/CompanyDetails.vue'
import PeriumProfile from '@/components/client-configuration/PeriumProfile.vue'
import { useI18n } from 'vue-i18n-composable'
import { useActions, useState } from '@/shared/mixins/helpers'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import query from '@/shared/queries/clientConfigQuery'
import { useToast } from 'vue-toastification/composition'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import Users from '@/components/client-configuration/Users.vue'
import { UserRole } from '@/shared/enum/general-enum'
import generalData from '@/assets/data/general-data.json'
import Confirmation from '@/shared/components/Confirmation.vue'
import { NormType } from '@/shared/enum/norms-enum'
import { switchLanguage } from '@/shared/services/I18nServices'

export default defineComponent({
    name: 'ClientConfiguration',
    components: {
        ContactDetails,
        CompanyDetails,
        PeriumProfile,
        Users,
        Confirmation,
    },
    setup() {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const editable = ref(false)
        const ContactDetailsComp = ref(null)
        const CompanyDetailsComp = ref(null)
        const PeriumProfileComp = ref(null)
        const UsersComp = ref(null)
        const clientConfigData = ref(null)
        const toast = useToast()
        const { role } = useState(['role'])
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const loading = ref(false)
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const { SET_NORM_TYPES } = useActions(['SET_NORM_TYPES'])
        const { SET_SELECTED_NORMS } = useActions(['SET_SELECTED_NORMS'])
        const { menuOpen } = useState(['menuOpen'])
        const visibleLeft = ref(false)
        const displayCancelConfirmation = ref(false)
        const isPCAvailable = ref(false)
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const allUsers = ref([])
        // add spaces between camel case letters
        const nameWithSpace = (name) => {
            return name.replace(/([A-Z])/g, ' $1').trim()
        }

        // store norm after updating the client config
        const storeNormType = () => {
            const selectedNorms = clientConfigData.value.selectedNorms
            const normTypes = []
            // store norm types and other norm types (`OTHER - otherNormType`) into `normTypes`
            selectedNorms.map((norm) => {
                norm.normType !== NormType.OTHER
                    ? normTypes.push(norm.normType)
                    : normTypes.push(NormType.OTHER + ' - ' + norm.normTypeOther)
            })

            // store norm types
            SET_NORM_TYPES(normTypes)
            // store norms
            SET_SELECTED_NORMS(selectedNorms)
        }

        // get client configuration data
        const getClientConfigData = async () => {
            try {
                loading.value = true
                const getControlDetailQuery = `
                    query{
                        organisationconfig(orgId:${organisationId}){
                            ${query.CLIENT_CONFIGURATION_DETAILS}
                        }
                    }
                `

                const result = await apolloClient.getGraphqlData(getControlDetailQuery)
                if (result) {
                    const clientConfigResult = result.data.organisationconfig
                    clientConfigData.value = clientConfigResult
                    editable.value = !clientConfigResult
                    storeNormType()
                }
            } catch (err) {
                throw Error(`Error while retreiving client config`)
            } finally {
                loading.value = false
            }
        }

        // update logged in user role info
        const updateLoggedInUserRole = (usersDetails: [{ id: number }]) => {
            // update user info in localstorage with updated data(required when switching organisations)
            const loggedInUserDetails = {
                user: usersDetails.filter((val) => val.id == userId)[0],
            }
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.USER, loggedInUserDetails)
        }

        // get list of users
        const getUsers = async () => {
            const usersQuery = `query {users {${query.USERS}}}`

            const result = await apolloClient.getGraphqlData(usersQuery)
            if (result) {
                const users = result.data.users
                allUsers.value = utils.sortbyAlphabeticOrder(users, 'firstName')
                updateLoggedInUserRole(users)
            }
        }

        // create and update client configuration data
        const saveClientConfiguration = async () => {
            // validate login form
            ContactDetailsComp.value.validateForm()
            CompanyDetailsComp.value.validateForm()
            // check for validation errors
            if (
                utils.hasErrors(ContactDetailsComp.value.errors) ||
                utils.hasErrors(CompanyDetailsComp.value.errors)
            ) {
                return
            }
            const contactDetailsForm = ContactDetailsComp.value.CONTACT_DETAILS_FORM
            const companyDetailsForm = CompanyDetailsComp.value.companyDetailsForm
            const periumProfileForm = PeriumProfileComp.value.periumProfileForm
            const selectedNorms = PeriumProfileComp.value.selectedNorms
            const selectedMSRNorms = PeriumProfileComp.value.selectedMSRNorms
            // get only selected norm ids
            const normIds = selectedNorms && selectedNorms.map((norms) => norms.id)
            const msrNormIds = selectedMSRNorms && selectedMSRNorms.map((norms) => norms.id)

            const clientConfigurationInput = {
                contactName: contactDetailsForm.NAME,
                contactEmail: contactDetailsForm.EMAIL,
                contactTelephone: contactDetailsForm.PHONE,
                interfaceLanguage: String(contactDetailsForm.INTERFACE_LANGUAGE),
                companyName: companyDetailsForm.COMPANY_NAME,
                cocNumber: companyDetailsForm.COC_NUMBER,
                companyStreet: companyDetailsForm.ADDRESSES_BUSSINESS.COMPANY_STREET,
                companyStreetNumber: companyDetailsForm.ADDRESSES_BUSSINESS.COMPANY_STREET_NO,
                companyPostalcode: companyDetailsForm.ADDRESSES_BUSSINESS.COMPANY_POSTALCODE,
                companyCity: companyDetailsForm.ADDRESSES_BUSSINESS.COMPANY_CITY,
                companyCountry: companyDetailsForm.ADDRESSES_BUSSINESS.COMPANY_COUNTRY,
                companyTelephone: companyDetailsForm.ADDRESSES_BUSSINESS.COMPANY_PHONE,
                financeStreet: companyDetailsForm.ADDRESSES_BILLING.FINANCE_STREET,
                financeStreetNumber: companyDetailsForm.ADDRESSES_BILLING.FINANCE_STREET_NO,
                financePostalcode: companyDetailsForm.ADDRESSES_BILLING.FINANCE_POSTALCODE,
                financeCity: companyDetailsForm.ADDRESSES_BILLING.FINANCE_CITY,
                financeCountry: companyDetailsForm.ADDRESSES_BILLING.FINANCE_COUNTRY,
                financeEmail: companyDetailsForm.ADDRESSES_BILLING.FINANCE_EMAIL,
                selectedRiskSets: periumProfileForm.SELECTED_RISK_SETS,
                selectedNorms: normIds,
                selectedMsrNorms: msrNormIds,
                riskToleranceGreen: periumProfileForm.RISK_TOLERANCE.GREEN,
                riskToleranceOrange: periumProfileForm.RISK_TOLERANCE.ORANGE,
                riskToleranceRed: periumProfileForm.RISK_TOLERANCE.RED,
                dataShare: periumProfileForm.DATA_SHARE === 1 ? true : false,
                twoFactorRequired: periumProfileForm.TWO_FACTOR_REQUIRED === 1 ? true : false,
                companySector: companyDetailsForm.COMPANY_SECTOR,
                companySubSector: companyDetailsForm.COMPANY_SUB_SECTOR,
                companySize: companyDetailsForm.COMPANY_SIZE,
                selectedPriorityCategories: periumProfileForm.PRIORITY_CATEGORIES,
                priorityCategoryActivated: periumProfileForm.PRIORITY_FILTER,
                isEmailReportScheduled: periumProfileForm.EMAIL_SCHEDULED,
            }

            loading.value = true
            let mutationQuery

            // create new client configuration
            if (!clientConfigData.value) {
                mutationQuery = `mutation CreateOrganisationConfig ($input: OrganisationConfigInput!) {
                    createOrganisationConfig(input: $input) {
                            status
                            error
                    }
                }`
            } else {
                // update client configuration
                mutationQuery = `mutation UpdateOrganisationConfig ($input: OrganisationConfigInput!) {
                    updateOrganisationConfig(id:${clientConfigData.value.id}, input: $input) {
                            status
                            error
                    }
                }`
            }

            try {
                const result = await apolloClient.updateGraphqlData(
                    mutationQuery,
                    clientConfigurationInput
                )
                // Result is in a different field depending on which query was used
                const clientConfigResult = result.data?.createOrganisationConfig ?? result.data?.updateOrganisationConfig
                if (!clientConfigResult?.status) {
                    throw Error(`Error while saving client config: ${clientConfigResult?.message}.`)
                }
                    /*
                    * At this point, the client config has been updated successfully.
                    * If the interfaceLanguage field was changed, we may need to switch the
                    * interface language in the interface. So check if this has changed, and
                    * if so, call switchLanguage to allow the I18nServices module to make
                    * sure the language change takes effect.
                    */

                if (clientConfigurationInput.interfaceLanguage !== language.value) {
                    switchLanguage(clientConfigurationInput.interfaceLanguage)
                }
                editable.value = false
                toast.success(t('CLIENT_CONFIGURATION_SAVE_SUCCESS', language.value))
                await getClientConfigData()
                loading.value = false
                await getUsers()
            } catch (err) {
                toast.error(t('CLIENT_CONFIGURATION_SAVE_ERROR', language.value))
                throw Error(`Error while saving client config data: ${err}`)
            } finally {
                loading.value = false
            }
        }

        // cancel button
        const cancel = () => {
            if (editable.value && clientConfigData.value) {
                // cancel when editing existing client configuration
                editable.value = false
                ContactDetailsComp.value.setFormData()
                CompanyDetailsComp.value.resetFormToPropsData()
                PeriumProfileComp.value.setFormData()
            } else {
                // cancel when creating new client
                ContactDetailsComp.value.cancelForm()
                CompanyDetailsComp.value.clearForm()
                PeriumProfileComp.value.cancelForm()
            }
            displayCancelConfirmation.value = false
        }

        onMounted(() => {
            getClientConfigData()
            getUsers()
        })

        const isAdminRole = computed(() => {
            return (
                role.value === UserRole.ADMIN ||
                role.value === UserRole.PERIUM_ADMIN ||
                role.value === UserRole.DEVELOPER_ADMIN
            );
        })

        const isEditable = computed(() => {
            return editable.value && isAdminRole.value
        })
        
        return {
            t,
            language,
            editable,
            saveClientConfiguration,
            ContactDetailsComp,
            CompanyDetailsComp,
            PeriumProfileComp,
            UsersComp,
            cancel,
            clientConfigData,
            variables,
            role,
            loading,
            utils,
            menuOpen,
            visibleLeft,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            nameWithSpace,
            UserRole,
            generalData,
            displayCancelConfirmation,
            isPCAvailable,
            allUsers,
            getUsers,
            isEditable,
            isAdminRole
        }
    },
})





















































































































import { useActions, useState } from '@/shared/mixins/helpers'
import { defineComponent, Ref, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import query from '@/shared/queries/clientConfigQuery'
import variables from '@/shared/variables'
import { useToast } from 'vue-toastification/composition'
import { UserRole, YesNo } from '@/shared/enum/general-enum'
import generalData from '@/assets/data/general-data.json'
import utils from '@/shared/mixins/utils'
import UpdateUser from './UpdateUser.vue'
import InviteUser from './InviteUser.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import Table from '@/shared/components/Table.vue'

export default defineComponent({
    name: 'Users',
    components: {
        UpdateUser,
        InviteUser,
        Table,
    },
    props: {
        allUsers: {
            type: Array,
        },
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const copyAllUsers: Ref = ref([])
        const labels = ref([])
        const displayEditPopup = ref(false)
        const roles = ref([])
        const hiddenRoles = ref([
            UserRole.SUPER_ADMIN,
            UserRole.PERIUM_ADMIN,
            UserRole.DEVELOPER_ADMIN,
        ])
        const { role } = useState(['role'])
        const toast = useToast()
        const displayInviteUserDialog = ref(false)
        const userTableData: Ref = ref({})
        const loading = ref(false)
        const editable = ref(false)
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const userFormCmp = ref(null)
        const { SET_USERS } = useActions(['SET_USERS'])
        // get logged in user info
        const loggedInUser: { user: { id } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const userList = ref([])
        const tableCmp = ref(null)
        const PASSWORD = ref('')
        const errors = ref({
            PASSWORD: { missingValue: false, invalidValue: false },
        })
        const displayPasswordConfirmation = ref(false)
        const serverError = ref('')
        const userInput = ref({
            firstName: '',
            lastName: '',
            roles: roles,
            startDate: '',
            endDate: '',
            twoFactorRequired: false,
            password: '',
        })

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('CLIENT_CONFIGURATION_USERS_TABLE_NAME', language.value),
                    fieldName: 'fullName',
                    pendingUser: true,
                },
                {
                    header: t('CLIENT_CONFIGURATION_USERS_TABLE_ROLE', language.value),
                    fieldName: 'roles',
                    list: true,
                },
                {
                    header: t('CLIENT_CONFIGURATION_USERS_TABLE_EMAIL', language.value),
                    fieldName: 'email',
                },
                {
                    header: t('CLIENT_CONFIGURATION_USERS_TABLE_START_DATE', language.value),
                    fieldName: 'startDate',
                    type: 'date',
                },
                {
                    header: t('CLIENT_CONFIGURATION_USERS_TABLE_END_DATE', language.value),
                    fieldName: 'endDate',
                    type: 'date',
                },
            ]
        }

        // get list of role
        const getRoles = async () => {
            const getRolesQuery = `
                query{
                    roles{
                        ${query.ROLES}
                    }
                }
            `
            let result
            try {
                result = await apolloClient.getGraphqlData(getRolesQuery)
            } catch (err) {
                if (result) {
                    const rolesOriginalResult = result.data.roles

                    roles.value = rolesOriginalResult
                }
            }
        }

        // add spaces between camel case letters
        const nameWithSpace = (name) => {
            return name.replace(/([A-Z])/g, ' $1').trim()
        }

        // set user dropdown option values
        const setUserDropdownValues = () => {
            let userDropdownOptions = []
            copyAllUsers.value.map((user) => {
                // get current organisation
                const currentOrganisation = user.organisations.filter(
                    (organisation) => organisation.id === organisationId
                )
                // get user expireation state
                const userExpired = utils.checkUserExpire(
                    currentOrganisation[0].startDate,
                    currentOrganisation[0].endDate
                )
                // get formatted name with adding (inactive) text next to name for expired users
                userDropdownOptions.push({
                    name: user.firstName + ' ' + user.lastName,
                    id: user.id,
                    username: user.username,
                    organisations: user.organisations,
                    userExpired,
                    fname: userExpired
                        ? user.firstName +
                          ' ' +
                          user.lastName +
                          ' (' +
                          t('INACTIVE', language.value) +
                          ')'
                        : user.firstName + ' ' + user.lastName,
                })
            })
            userDropdownOptions = utils.sortbyAlphabeticOrder(userDropdownOptions, 'fname')
            // update employee dropdown values everywhere
            SET_USERS(userDropdownOptions)
        }

        const getSelectedUser = (id) => {
            const user = userList.value.filter((val) => val.id === id)[0]
            user.lastRecordNo = utils.padLeft('000', userList.value.length)
            userTableData.value = user
        }

        // go to user record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`.
            `userList` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // use the id of the record to get next/previous details
                getSelectedUser(record.id)
            }
        }

        // updating userList array with additional fields
        const toTableFormat = (tempResultData) => {
            userList.value = []
            tempResultData.map((user, index) => {
                let userCopy = {
                    no: 0,
                    fullName: '',
                    roles: [],
                    startDate: null,
                    endDate: null,
                    inactive: false,
                }
                userCopy = Object.create(user) // creating a copy of user object
                userCopy.no = utils.padLeft('000', index + 1) // creating no
                userCopy.fullName = user.firstName + ' ' + user.lastName
                // get user loggedin organisation
                const currentOrganisation = user.organisations.filter(
                    (org) => org.id === Number(organisationId)
                )
                // get roles within current organisation
                const roles = currentOrganisation[0].roles
                userCopy.roles = roles.map((role) =>
                    t(utils.keyToName(generalData.ROLE, role.name), language)
                )
                // get and set startDate and endDate of current organisation
                userCopy.startDate = currentOrganisation[0].startDate
                userCopy.endDate = currentOrganisation[0].endDate
                // only display SUPER_ADMIN, PERIUM_ADMIN and DEVELOPER_ADMIN for themselves
                if (!hiddenRoles.value.includes(role.value)) {
                    const hiddenRoles = roles.filter(
                        (element) =>
                            ![
                                UserRole.SUPER_ADMIN,
                                UserRole.PERIUM_ADMIN,
                                UserRole.DEVELOPER_ADMIN,
                            ].includes(element.name)
                    )
                    userCopy.roles = hiddenRoles.map((role) =>
                        t(utils.keyToName(generalData.ROLE, role.name), language)
                    )
                }
                // get user expireation state
                const userExpired = utils.checkUserExpire(
                    currentOrganisation[0].startDate,
                    currentOrganisation[0].endDate
                )
                userCopy.inactive = userExpired

                userList.value.push(userCopy)
            })
        }

        // send invitation to the user
        const inviteUser = async (formData) => {
            const input = {
                email: formData.EMAIL,
                firstName: formData.FIRST_NAME,
                lastName: formData.LAST_NAME,
                roles: formData.ROLES.map((role) => role.id),
                startDate: formData.START_DATE,
                endDate: formData.END_DATE,
            }

            const mutationQuery = `
                    mutation($input: CreateUserInputPayload){
                        inviteUser(input: $input){
                            status
                            error
                            errorCode{
                                value
                            }
                        }
                    }
                `
            // set loader for response wait
            loading.value = true
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
                loading.value = false
            } catch (err) {
                loading.value = false
                toast.error(t('INVITE_USER_SAVE_ERROR', language.value))
                throw Error('Error while saving user')
            }
            if (result.data.inviteUser.status) {
                toast.success(t('INVITE_USER_SAVE_SUCCESS', language.value))
                displayInviteUserDialog.value = false
                // refresh user list
                emit('get-users')
            } else {
                if (result.data.inviteUser && result.data.inviteUser.errorCode.value) {
                    const error = result.data.inviteUser.errorCode.value
                    toast.error(t(utils.getErrorMessage(error), language.value))
                } else {
                    toast.error(t('INVITE_USER_SAVE_ERROR', language.value))
                }
            }
        }

        // open user information update popup
        const openUserDetailsPopup = (id) => {
            editable.value = false
            displayEditPopup.value = true

            getSelectedUser(id)
        }

        // update logged in user role info
        const updateLoggedInUserRole = (updatedUserInfo) => {
            // get current org id
            const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
                decrypt: true,
            })
            // get organisations from returned updated user info and filter with current org
            const organisation = updatedUserInfo.organisations.filter(
                (val) => val.id == organisationId
            )
            // get roles returned from user info (for current org)
            const updatedRoles = organisation[0].roles
            // set updated user roles
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.ROLES, updatedRoles)

            // update user info in localstorage with updated data(required when switching organisations)
            const loggedInUserDetails = {
                user: copyAllUsers.value.filter((val) => val.id == userId)[0],
            }
            utils.ls.set(variables.LOCAL_STORAGE_ITEMS.USER, loggedInUserDetails)
        }

        const validateForm = () => {
            // reset errors
            errors.value = {
                PASSWORD: { missingValue: false, invalidValue: false },
            }
            // missingValue check
            errors.value.PASSWORD.missingValue = utils.validateFields(PASSWORD.value, 'string')
        }

        // reset password confirmation properties
        const resetPasswordConfirmation = () => {
            // reset form data, errors
            PASSWORD.value = ''
            errors.value = {
                PASSWORD: { missingValue: false, invalidValue: false },
            }
            serverError.value = ''
        }

        /* fill userInput with form data values when click on save of edit user popup to use later 
            if incase to use it after passowrd confirmation*/
        const saveUpdateUserFormData = (params: {
            FIRST_NAME: string;
            LAST_NAME: string;
            ROLES: [{ id: number }];
            START_DATE: string;
            END_DATE: string;
            TWOFACTOR_REQUIRED: YesNo;
        }) => {
            if (params && !displayPasswordConfirmation.value) {
                userInput.value.firstName = params.FIRST_NAME
                userInput.value.lastName = params.LAST_NAME
                userInput.value.roles = params.ROLES.map((role) => role.id)
                userInput.value.startDate = params.START_DATE
                userInput.value.endDate = params.END_DATE
                userInput.value.twoFactorRequired =
                    params.TWOFACTOR_REQUIRED === YesNo.YES ? true : false
            }
        }

        // save client configurations
        const saveUser = async (params) => {
            saveUpdateUserFormData(params)
            userInput.value.password = PASSWORD.value

            // when password confirmation is on display
            if (displayPasswordConfirmation.value) {
                // validate twofact auth
                validateForm()
                // check for validation errors
                if (utils.hasErrors(errors.value)) {
                    return
                }
            }

            // when twoFactorRequired is updating from required to not required, display password confirmation popup
            if (
                userTableData.value.twoFactorRequired &&
                params.TWOFACTOR_REQUIRED === YesNo.NO &&
                !displayPasswordConfirmation.value
            ) {
                displayPasswordConfirmation.value = true
                return
            }

            const mutationQuery = `mutation ($input: UpdateUserInputPayload!) {
                updateUser(id: ${userTableData.value.id}, input: $input) {
                    ${query.UPDATE_USER_RETURN}
                    status
                    error
                    errorCode{
                        value
                    }
                }
            }`

            const input = userInput.value
            let result
            try {
                result = await apolloClient.updateGraphqlData(mutationQuery, input)
            } catch (err) {
                toast.error(t('CLIENT_CONFIGURATION_USER_INFORMATION__SAVE_ERROR', language.value))
                throw Error('Error while saving saving user information')
            }
            if (result) {
                if (result.data.updateUser.status) {
                    displayEditPopup.value = false
                    displayPasswordConfirmation.value = false
                    resetPasswordConfirmation()

                    emit('get-users')
                    // if user updating their own roles
                    if (userId === userTableData.value.id) {
                        updateLoggedInUserRole(result.data.updateUser.user)
                    }

                    toast.success(
                        t('CLIENT_CONFIGURATION_USER_INFORMATION_SAVE_SUCCESS', language.value)
                    )
                } else {
                    // display error message if there is a error message mapping to particular error code
                    if (utils.getErrorMessage(result.data.updateUser.errorCode.value)) {
                        errors.value.PASSWORD.invalidValue = true
                        serverError.value = utils.getErrorMessage(
                            result.data.updateUser.errorCode.value
                        )
                        return
                    }
                    // else display general user data save fails error
                    toast.error(
                        t('CLIENT_CONFIGURATION_USER_INFORMATION__SAVE_ERROR', language.value)
                    )
                }
            }
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close view details entity if it's open
                    if (displayEditPopup.value) {
                        displayEditPopup.value = false
                    }
                    // close create new entity if it's open
                    if (displayInviteUserDialog.value) {
                        displayInviteUserDialog.value = false
                    }
                }
            })
        }

        // close password confirmation popup
        const cancelConfirmation = () => {
            displayPasswordConfirmation.value = false
            resetPasswordConfirmation()
        }

        // waitching for allUsers data changes
        watch(
            () => props.allUsers,
            async () => {
                setTableHeaders()
                closeSidebarEsc()
                getRoles()
                if (!props.allUsers) {
                    return
                }
                copyAllUsers.value = props.allUsers
                setUserDropdownValues()
                toTableFormat(copyAllUsers.value)
            },
            {
                immediate: true,
            }
        )
        // watch for language chaneges and update labels
        watch(language, () => {
            setTableHeaders()
            toTableFormat(copyAllUsers.value)
        })

        return {
            t,
            language,
            labels,
            copyAllUsers,
            displayEditPopup,
            userTableData,
            editable,
            variables,
            roles,
            hiddenRoles,
            role,
            saveUser,
            nameWithSpace,
            UserRole,
            generalData,
            utils,
            displayInviteUserDialog,
            userFormCmp,
            inviteUser,
            tableHeaders,
            userList,
            openUserDetailsPopup,
            loading,
            tableCmp,
            goToRecord,
            PASSWORD,
            errors,
            displayPasswordConfirmation,
            serverError,
            cancelConfirmation,
        }
    },
})
